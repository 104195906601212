<template>
  <div
    class="container"
  >
    <section class="app-ecommerce-details">

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="product === undefined"
      >
        <h4 class="alert-heading">
          Error obteniendo la información del producto
        </h4>
        <div class="alert-body">
          No se ha encontrado el producto con ese código, ve a la
          <b-link
            class="alert-link"
            :to="{ name: 'apps-e-commerce-shop'}"
          >
            tienda
          </b-link>
          y explora otros.
        </div>
      </b-alert>

      <!-- Content -->
      <div
        v-if="product"
        no-body
      >
        <div>
          <b-row class="my-2">

            <!-- Left: Product Image Container -->
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <b-container>
                <b-row>
                  <b-col
                    cols="12"
                    lg="12"
                    order="2"
                    class="height-mobile"
                  >
                    <swiper
                      ref="productSlideThumbs"
                      class="swiper gallery-thumbs"
                      :options="swiperOptionThumbs"
                    >
                      <swiper-slide
                        v-for="image in product.thumbnails"
                        :key="image"
                      >
                        <b-img
                          :src="image"
                          :alt="`${product.name}-${product.id}`"
                          class="img-fluid"
                          fluid
                        />
                      </swiper-slide>
                    </swiper>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="12"
                    order="1"
                  >
                    <swiper
                      ref="productSlideShow"
                      class="swiper-gallery gallery-top"
                      :options="swiperOption"
                    >
                      <swiper-slide
                        v-for="(image, index) in product.thumbnails"
                        :id="product.media[index].id"
                        :key="product.media[index].id"
                        :virtual-index="product.media[index].id"
                        class="text-center"
                      >
                        <b-img
                          :src="image"
                          :alt="`${product.name}-${product.id}`"
                          class="mx-auto w-100"
                          fluid9-
                        />
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next swiper-button-white"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev swiper-button-white"
                      />
                    </swiper>
                  </b-col>
                </b-row>

              </b-container>
            </b-col>

            <!-- Right: Product Details -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Avability -->
              <b-card-text v-if="product.stock > 0 && product.state === 'active'">
                <div class="gradient-border">
                  <span class="category-button btn btn-block">
                    <svg
                      width="68"
                      height="13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          id="textGradient"
                          x1="0"
                          x2="1"
                          y1="0"
                          y2="0"
                        >
                          <stop
                            offset="0%"
                            stop-color="#F6A21B"
                          />
                          <stop
                            offset="50%"
                            stop-color="#EC2C39"
                          />
                          <stop
                            offset="100%"
                            stop-color="#4A2268"
                          />
                        </linearGradient>
                      </defs>
                      <text
                        x="0"
                        y="10"
                        fill="url(#textGradient)"
                        font-size="15"
                        font-weight="bold"
                      >En Stock</text>
                    </svg>
                  </span>
                </div>
              </b-card-text>
              <b-card-text v-else>
                <div class="border-danger d-inline-block p-stock">
                  <span class="text-danger">Sin Stock</span>
                </div>
              </b-card-text>

              <!-- Product Name -->
              <h1 class="font-weight-bolder">
                {{ product.name }}
              </h1>

              <!-- Price And Ratings -->
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h2 class="mr-1 font-large-2">
                  ${{ product.price }}
                </h2>
              </div>

              <!-- Product Description -->
              <b-card-text v-html="product.description" />

              <!--              <b-card-text
                v-if="product.payment_methods.length"
              >
                <b-alert
                  variant="warning"
                  show
                  class="d-inline-block"
                >
                  <div class="alert-body">
                    <span>Este producto solo permite la compra mediante estos métodos de pago:</span>
                    <ul class="product-features list-style-circle">
                      <li
                        v-for="paymentMethod in product.payment_methods"
                        :key="paymentMethod.id"
                      >
                        + {{ paymentMethod.description }}
                      </li>
                    </ul>
                  </div>
                </b-alert>
              </b-card-text>-->

              <b-form-group
                v-if="brands.length"
                label="Personaliza Logotipo:"
              >
                <b-form-radio-group
                  id="brand-selector"
                  v-model="brand"
                  class="btn-group-types"
                  button-variant="outline-primary"
                  :options="brands"
                  buttons
                  name="brand"
                  value-field="id"
                  text-field="value"
                  @change="changeBrand"
                />
              </b-form-group>

              <b-form-group
                v-if="genders.length"
                label="Género"
              >
                <b-form-radio-group
                  id="size-selector"
                  v-model="gender"
                  class="btn-group-types"
                  button-variant="outline-primary"
                  :options="genders"
                  buttons
                  name="gender"
                  value-field="id"
                  text-field="value"
                />
              </b-form-group>

              <b-form-group
                v-if="colors.length"
                label="Color"
              >
                <b-form-radio-group
                  id="color-selector"
                  v-model="color"
                  button-variant="outline-primary"
                  :options="colors"
                  buttons
                  class="btn-group-types"
                  name="color"
                  value-field="id"
                  text-field="value"
                  @change="changeColor"
                />
              </b-form-group>

              <b-form-group
                v-if="sizes.length"
                label="Talla"
              >
                <b-form-radio-group
                  id="size-selector"
                  v-model="size"
                  button-variant="outline-primary"
                  :options="sizes"
                  buttons
                  name="size"
                  value-field="id"
                  text-field="value"
                />
              </b-form-group>

              <b-form-group
                v-if="fragrances.length"
                label="Fragrancia"
              >
                <b-form-radio-group
                  id="fragrance-selector"
                  v-model="fragrance"
                  button-variant="outline-primary"
                  :options="fragrances"
                  buttons
                  name="fragrance"
                  value-field="id"
                  text-field="value"
                  @change="changeFragrance"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <div class="product-purchase">
                <div
                  v-if="product.free_shipping_enabled"
                  class="free-shipping"
                >
                  <feather-icon
                    icon="PackageIcon"
                    size="16"
                  />
                  Envío gratis a todo el país
                </div>
                <!-- Product Meta [Free shpping, EMI, etc.] -->
                <ul
                  v-if="product.free_shipping_enabled"
                  class="product-features list-unstyled"
                >
                  <li v-if="product.qty_free_shipping === 1 || product.qty_free_shipping === 0">
                    <feather-icon icon="ShoppingCartIcon" />
                    <span>Envío gratis</span></li>
                  <li v-else>
                    <feather-icon icon="DollarSignIcon" />
                    <span>A partir de la compra de {{ product.qty_free_shipping }} paquetes</span>
                  </li>
                </ul>
                <div>
                  <p>
                    <strong>Cantidad</strong>
                  </p>
                  <b-form-spinbutton
                    id="sb-inline"
                    v-model="value"
                    inline
                  />
                  <span class="ml-1">Paquetes</span>
                </div>
                <div class="pt-1 w-100">
                  <b-button
                    class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0 w-100 btn-gradient"
                    @click="buyNow(product)"
                  >
                    <span>Comprar ahora</span>
                  </b-button>
                </div>
                <!--todo:agregar funcionalidad a comprar ahora-->
                <div class="pt-1 w-100">
                  <b-button
                    class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0 w-100 btn-add-cart"
                    :disabled="blocking || product.state === 'unavailable'"
                    @click="handleCartActionClick(product, {brand, color, size, gender, fragrance, qty: value})"
                  >
                    <span>{{ product.isInCart ? 'Ver en el carrito' : 'Agregar al carrito' }}</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </section>

    <section class="bg-grey bg mt-4">
      <b-row class="content-wrapper2 row">
        <b-col
          lg="12"
          xl="11"
          class="d-block m-auto"
        >
          <h2 class="title-products text-center mt-4 mb-3">
            Productos relacionados
          </h2>
          <!-- todo:poner los productos relacionados aqui suguiero que sean random de la misma categoria-->
          <b-row>
            <b-col
              v-for="product in products"
              :key="product.id"
              lg="3"
              md="6"
              sm="12"
              class="mb-3"
            >
              <div class="content-item">
                <b-link
                  class="d-block m-auto"
                  :to="{ name: 'product-details', params: { slug: product.slug } }"
                >
                  <div class="img-overlay">
                    <span
                      v-if="product.discount_price"
                      class="float-discont"
                    >-{{ ((product.discount_price-product.price)/product.discount_price*100).toFixed(2) }}%</span>
                    <img
                      loading="lazy"
                      :src="product.thumbnails[0]"
                      :alt="`${product.name}-${product.id}`"
                      class="img-overlay-image"
                    >
                    <div class="overlay">
                      <div class="text">
                        Más información
                      </div>
                    </div>
                  </div>
                </b-link>

                <div class="item-rating">
                  <ul class="unstyled-list list-inline">
                    <div v-if="product.free_shipping_enabled">
                      <b-badge
                        v-if="product.qty_free_shipping === 1"
                        pill
                        variant="light-success"
                      >
                        Envío gratis
                      </b-badge>

                      <b-badge
                        v-else
                        variant="light-success"
                      ><!-- todo: aquí hay que ponerlo decolores segun el articulo-->
                        Envío gratis a partir de {{ product.qty_free_shipping }} paq.
                      </b-badge>
                    </div>
                  </ul>
                </div>
                <div>
                  <p class="item-description mb-0">
                    {{ product.name }}
                  </p>
                  <h4 class="item-price">
                    ${{ product.price }} C/U
                    <strike
                      v-if="product.discount_price"
                      class="small"
                    >
                      ${{ product.discount_price }} C/U
                    </strike>
                  </h4>
                </div>

              </div>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <section>
      <b-row class="d-block">
        <b-col
          lg="12"
          xl="10"
          class="d-block m-auto text-center pt-5"
        >
          <h2 class="title-products mt-1 mb-3">
            Preguntas frecuentes
          </h2>

          <app-collapse
            accordion
            type="margin"
            class="faq-home"
          >

            <app-collapse-item
              class="faq-title"
              title="¿Cómo puedo hacer un pedido en PromoShop?"
            >
              <ol>
                <li>Accesa a la categoría de productos que te interesa</li>
                <li>Una vez seleccionado el artículo a comprar, elige la marca y color que deseas adquirir</li>
                <li>Presiona el botón "agregar al carrito"</li>
                <li>En caso de querer seleccionar más productos, da clic a la categoría de tu interés para seguir agregándolos a tu compra</li>
                <li>Una vez que estés listo para finalizar tu compra, haz clic en "ver en mi carrito"</li>
                <li>Revisa los detalles de tu compra y da clic en "siguiente"</li>
                <li>Selecciona o edita la dirección en donde quieres recibirlo</li>
                <li>Da clic en "siguiente" y selecciona el método de pago</li>
                <li>Da clic en "pagar"</li>
                <li>Revisa en la bandeja de entrada de tu correo la confirmación de compra</li>
              </ol>

            </app-collapse-item>

            <app-collapse-item title="¿Qué métodos de pago aceptan en Promoshop?">
              <ul>
                <li v-if="!isCentroAmerica">
                  Tarjeta / Crédito / Débito / Oxxo a través de Conekta
                </li>
                <li>Paga usando puntos</li>
                <li v-if="!isCentroAmerica">
                  Paga mediante SPEI
                </li>
                <li>Paga mediante el centro de costos (método de pago solo disponible para personal staff de Clarios)</li>
              </ul>
            </app-collapse-item>

            <app-collapse-item title="¿Cómo facturar mi compra?">
              <ol>
                <li>Ingresa a tus pedidos</li>
                <li>Selecciona el pedido a facturar</li>
                <li>Da clic en "Facturar"</li>
                <li>
                  Llena el campo de datos con la siguiente información:
                  <ul style="list-style-type: lower-alpha;">
                    <li>Registro Federal de Contribuyentes (R.F.C.)</li>
                    <li>Nombre completo o razón social sin régimen de capital</li>
                    <li>Código Postal de su domicilio fiscal vigente</li>
                    <li>Régimen fiscal registrado ante las autoridades fiscales</li>
                  </ul>
                </li>
              </ol>
              <p class="pl-2">
                Dichos datos deben ser válidos y vigentes para poder emitir la factura y se pueden obtener en el documento digital que emite el SAT denominado: “Constancia de Situación Fiscal”.
              </p>
              <p class="pl-2">
                En caso de que cualquier dato sea incorrecto o no coincida con los datos que están registrados ante las autoridades fiscales, no podrá emitirse la factura, pues son validados por el SAT como parte del proceso emisión en esta nueva versión 4.0.
              </p>
            </app-collapse-item>

            <app-collapse-item title="¿Cada cuánto tiempo se realiza la carga de puntos?">
              Se realizan cuatro cargas anuales de manera trimestral, cada una con una vigencia de seis meses. Cada vez que se realice una carga de puntos o la vigencia de los mismos se aproxime, se enviará un correo de notificación al usuario.
            </app-collapse-item>

            <app-collapse-item title="¿Los puntos son acumulables?">
              Los puntos sí son acumulables, siempre y cuando estén dentro de la fecha de vigencia indicada.
            </app-collapse-item>

            <app-collapse-item title="¿Las compras con puntos se pueden facturar?">
              No, unicamente las realizadas con pago de tarjeta o transferencia.
            </app-collapse-item>

            <app-collapse-item title="¿Cómo cancelar mi compra?">
              Contacta a servicio al cliente para poder cancelar tu compra. Solamente puedes cancelar las compras que están pendientes de pago.
            </app-collapse-item>

            <app-collapse-item
              v-if="!isCentroAmerica"
              title="Si ya realicé mi pago, ¿a dónde puedo enviar mis datos?"
            >
              Puedes enviar tus datos a soporte@promoshopclarios.com o al whatsapp 6647060354 de Lunes a Viernes de 9 am a 5 pm
            </app-collapse-item>

            <app-collapse-item title="¿Puedo hacer la compra de varios productos a la vez?">
              Efectivamente, no hay un límite de compra en cantidad o variedad.
            </app-collapse-item>

            <app-collapse-item title="¿Cuáles son los tiempos de entrega?">
              <p class="pl-2">
                El tiempo de entrega varía de acuerdo al producto, podrás encontrar esta información en la descripción del mismo.
              </p>
              <p
                v-if="!isCentroAmerica"
                class="pl-2"
              >
                El rango estimado va desde 15 a 40 días hábiles.
              </p>
            </app-collapse-item>

            <app-collapse-item title="¿Cómo puedo hacer una solicitud especial? (variedad en tallas o marcas)">
              Puedes enviar tu solicitud con las especifiaciones al correo <a
                v-if="isCentroAmerica"
                href="mailto:soporte@promoshopclarioscam.com"
              >soporte@promoshopclarioscam.com</a> <a
                v-if="!isCentroAmerica"
                href="mailto:soporte@promoshopclarios.com"
              >soporte@promoshopclarios.com</a> o al whatsapp
              <a
                v-if="isCentroAmerica"
                href="https://wa.me/526647060354"
              >+52 664 7060 354</a> <a
                v-if="!isCentroAmerica"
                href="https://wa.me/6647060354"
              >664 7060 354</a> de Lunes a Viernes de 9 am a 5 pm.

            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BAlert,
  BFormGroup,
  BFormRadioGroup,
  BContainer,
  BFormSpinbutton,
  BBadge,
} from 'bootstrap-vue'
import { IS_CENTROAMERICA } from '@/config/app'
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  useShopFiltersSortingAndPagination,
  useShopRemoteData,
} from '@/views/e-commerce/e-commerce-shop/useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'
import 'swiper/css/swiper.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBadge,
    // BSV
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BAlert,
    BFormGroup,
    BFormRadioGroup,
    BContainer,
    Swiper,
    SwiperSlide,
    BFormSpinbutton,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: window.innerWidth > 768 ? 50 : 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      product: null,
      brand: null,
      color: null,
      size: null,
      gender: null,
      fragrance: null,
      brands: [],
      colors: [],
      sizes: [],
      genders: [],
      fragrances: [],
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 25,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        direction: 'horizontal',
      },
      blocking: true,
      value: 1,
    }
  },
  computed: {
    isCentroAmerica() {
      return IS_CENTROAMERICA
    },
    isDesktop() {
      return window.innerWidth > 768
    },
  },
  mounted() {
    const productSlug = this.$route.params.slug
    console.log(productSlug)

    store.dispatch('products/show', { productSlug })
      .then(async response => {
        console.log(response.data.products[0])
        // eslint-disable-next-line prefer-destructuring
        this.product = response.data.products[0]
        this.fetchShopProducts(`${this.product.taxons[0].taxonomy.slug}/${this.product.taxons[0].slug}`)

        await new Promise(r => setTimeout(r, 1000))

        console.log(this.$refs.productSlideShow)
        const swiperTop = this.$refs.productSlideShow.$swiper
        const swiperThumbs = this.$refs.productSlideThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop

        this.brands = this.product.variants.filter(variant => variant.key === 'brand')
        if (this.brands.length) {
          this.brand = this.brands[0].id
        }
        this.colors = this.product.variants.filter(variant => variant.key === 'color')
        if (this.colors.length) {
          this.color = this.colors[0].id
        }
        this.sizes = this.product.variants.filter(variant => variant.key === 'size')
        if (this.sizes.length) {
          this.size = this.sizes[0].id
        }
        this.genders = this.product.variants.filter(variant => variant.key === 'gender')
        if (this.genders.length) {
          this.gender = this.genders[0].id
        }
        this.fragrances = this.product.variants.filter(variant => variant.key === 'fragrance')
        if (this.fragrances.length) {
          this.fragrance = this.fragrances[0].id
        }
        this.blocking = false

        this.changeColor()
        this.changeBrand()
        this.changeFragrance()
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.status === 404) {
          this.product = undefined
        } else {
          this.changeColor()
          this.changeBrand()
          this.changeFragrance()
        }
      })
  },
  methods: {
    buyNow(product) {
      const self = this
      this.handleCartActionClick(product, {
        brand: self.brand, color: self.color, size: self.size, gender: self.gender, fragance: self.fragrance, qty: self.value,
      })
      setTimeout(() => {
        this.$router.push({ name: 'checkout' })
      }, 250)
    },
    changeBrand() {
      const brandId = this.brand
      const colorId = this.color
      const mediaFind = this.product.media.filter(media => {
        if (this.colors && media.custom_properties.color) {
          return media.custom_properties.brand === brandId && media.custom_properties.color === colorId
        }
        return media.custom_properties.brand === brandId
      })
      // eslint-disable-next-line consistent-return
      this.$refs.productSlideShow.$swiper.slides.each((index, slide) => {
        // eslint-disable-next-line eqeqeq
        if (slide.getAttribute('id') == mediaFind[0].id) {
          this.$refs.productSlideShow.$swiper.slideTo(index)
          return false
        }
      })
    },
    changeColor() {
      const colorId = this.color
      const brandId = this.brand
      const mediaFind = this.product.media.filter(media => {
        if (this.brands && media.custom_properties.brand) {
          return media.custom_properties.brand === brandId && media.custom_properties.color === colorId
        }
        return media.custom_properties.color === colorId
      })
      // eslint-disable-next-line consistent-return
      this.$refs.productSlideShow.$swiper.slides.each((index, slide) => {
        // eslint-disable-next-line eqeqeq
        if (slide.getAttribute('id') == mediaFind[0].id) {
          this.$refs.productSlideShow.$swiper.slideTo(index)
          return false
        }
      })
    },
    changeFragrance() {
      const fragranceId = this.fragrance
      const brandId = this.brand
      const mediaFind = this.product.media.filter(media => {
        if (this.brands && media.custom_properties.brand) {
          return media.custom_properties.brand === brandId && media.custom_properties.fragrance === fragranceId
        }
        return media.custom_properties.fragrance === fragranceId
      })
      // eslint-disable-next-line consistent-return
      this.$refs.productSlideShow.$swiper.slides.each((index, slide) => {
        // eslint-disable-next-line eqeqeq
        if (slide.getAttribute('id') == mediaFind[0].id) {
          this.$refs.productSlideShow.$swiper.slideTo(index)
          return false
        }
      })
    },
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    // UI
    const selectedColor = ref(null)
    const {
      filters, sortBy,
    } = useShopFiltersSortingAndPagination()

    const { products, fetchProducts } = useShopRemoteData()
    const fetchShopProducts = category => {
      fetchProducts({
        page: filters.value.page,
        search: filters.value.q,
        sortByPrice: sortBy.value.value,
        sortByCreatedAt: 'desc',
        perPage: filters.value.perPage,
        priceRange: filters.value.priceRange,
        categorization: category,
        properties: (filters.value.brands) ? {
          brand: filters.value.brands,
        } : null,
      })
        .then(response => {
          products.value = response.data.products.data.sort(() => Math.random() - 0.5).slice(0, 4)
        })
    }

    return {
      filters,
      sortBy,
      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
      fetchShopProducts,

      // useShopRemoteData
      products,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
.dark-layout{
  .category-button {
    background-color: $theme-dark-input-bg !important;
  }
  .gradient-border {
    background: linear-gradient(to right, #F6A21B, #EC2C39) !important;
  }
  .bg-grey{
    background-color: $theme-dark-card-bg;
  }
  .img-overlay {
    background-color: $theme-dark-input-bg;
  }
  .btn-cart{
    color: #b4b7bd!important;
  }
}
.points{
  color:#EC2C39;
  font-weight: bolder;
}
.swiper-gallery{
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}
.gradient-border {
  display: inline-block;
  padding: 2px; // Esto se comporta como el borde
  background: linear-gradient(to right, #F6A21B, #EC2C39, #4A2268);
  border-radius: 7px; // 2px (borde) + 5px (border-radius del botón) = 7px
  transition: background 0.3s;

  .category-button {
    display: block;
    background-color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    padding: 7px 10px;
  }
}
.bg {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.bg-grey{
  background-color: #f2f2f2;
}
.content-wrapper2 {
  margin-left: 50vw;
  margin-right: 50vw;
  box-sizing: border-box;

  > * {
    box-sizing: content-box;
  }
}
.title-products{
  color: #43413D;
  font-size: 30px;
}
.img-overlay {
  .float-discont{
    z-index: 5!important;
    position: absolute;
    color: #fff;
    font-weight: 700;
    right: 10px;
    top: 10px;
    padding: 1px 6px;
    background-color: #EC2C39;
  }
  background-color: white;
  border: 1px solid #c2c2c2;
  position: relative; // necesitamos posición relativa para posicionar absolutamente el texto
  border-radius: 5px;
  margin-bottom: 15px;
  .img-overlay-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .overlay {
    position: absolute; // esto permitirá que el texto se superponga sobre la imagen
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center; // centrar horizontalmente
    align-items: center; // centrar verticalmente
    opacity: 0; // ocultar por defecto
    background-color: rgba(0, 0, 0, 0.4); // fondo negro con 50% de transparencia
    transition: opacity 0.3s ease-in-out; // transición suave al hacer hover

    .text {
      color: white;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &:hover {
    .overlay {
      opacity: 1; // mostrar al hacer hover
    }
  }
}
.btn-gradient{
  background: rgb(246,162,27);
  background: -moz-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6a21b",endColorstr="#4a2268",GradientType=1);
  border: none;
  &:hover{
      box-shadow: 0 8px 25px -8px rgba(236,44,57,.5) !important;
  }
}
.btn-add-cart{
  background-color: rgba(74,34,104,.2) !important;
  color: rgba(74,34,104,1) !important;
  border:none;
  &:hover{
    box-shadow: 0 8px 25px -8px rgba(74,34,104,.2) !important;
  }
}
.p-stock{
  padding: 7px 10px;
  border-radius: 5px;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.height-mobile {
  height: 100px;
}

.gallery-thumbs .swiper-slide {
  opacity: 0.4;
  margin-top: 20px;

}
.gallery-thumbs{
  img{
    border: 1px solid #D9D9D9!important;
    border-radius: 5px!important;
  }
}

.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
.swiper-button-prev {
  left: 0;

  &:after {
    background-image: url(str-replace(str-replace($chevron-left, 'currentColor', #D9D9D9), '#', '%23')) !important;
  }
}

.swiper-button-next {
  right: 0;

  &:after {
    background-image: url(str-replace(str-replace($chevron-right, 'currentColor', #D9D9D9), '#', '%23')) !important;
  }
}
.product-purchase{
  padding: 30px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;

  .free-shipping{
    background-color: #6BBC3A;
    padding: 5px 10px;
    display: inline-block;
    color: #fff;
    border-radius: 5px;
  }
}

.btn-group-types{
  display: block!important;
   .btn{
    border-radius: 10px!important;
    border-right-width: 1px!important;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.btn-group-toggle [class*=btn-outline-]:not(:last-child) {
  border-right-width: 1px !important;
}
</style>
